"use client";

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  scanCustomerCode,
  scanRewardRequestCode,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  HttpValidationProblemDetails,
  ScanCustomerPublicIdCommand,
  ScanCustomerPublicIdResponse,
  ScanRewardRequestCodeCommand,
  ScanRewardRequestCodeResponse,
} from "@repo/types/companyApi.types";
import { CodeTextInput, LoadingButton, Logo, TopBar } from "@repo/ui";
import { cmsRoutes, codeValidator, useServerErrorFormatter } from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { BottomAlert, UpperAlert, UpperAlertProps } from "../alerts";
import { isSafari } from "react-device-detect";

const codeTypes = ["reward", "points"] as const;
interface CodeFormValues {
  type: (typeof codeTypes)[number];
  code: string;
}

export default function EmployeeTextPage() {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { formatErrorMessage } = useServerErrorFormatter();

  const isStandalone = useMediaQuery(
    "@media all and (display-mode: standalone)"
  );

  const [upperAlertData, setUpperAlertData] = useState<Omit<
    UpperAlertProps,
    "onClose"
  > | null>(null);
  useEffect(() => {
    if (upperAlertData !== null) {
      const timer = setTimeout(() => {
        setUpperAlertData(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [upperAlertData]);

  const [bottomAlertPoints, setBottomAlertPoints] = useState<number | null>(
    null
  );
  useEffect(() => {
    if (bottomAlertPoints !== null) {
      const timer = setTimeout(() => {
        setBottomAlertPoints(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [bottomAlertPoints]);

  const { mutate: pointMutation, isPending: pointPending } = useMutation<
    ScanCustomerPublicIdResponse,
    AxiosError,
    ScanCustomerPublicIdCommand
  >({
    mutationFn: (values) => scanCustomerCode(getTenantId() || "", values),
    onSuccess: async (data) => {
      if (data.userScanStreak < 3) {
        setUpperAlertData({
          variant: "pointScan",
        });
      } else {
        setUpperAlertData({
          variant: "warning",
          scanStreak: data.userScanStreak,
        });
      }
      setBottomAlertPoints(data.pointsOwned);
      form.setFieldValue("code", "");
    },
    onError: async (data) => {
      setUpperAlertData({
        variant: "error",
        description: formatErrorMessage(
          data as AxiosError<HttpValidationProblemDetails>
        ),
      });
    },
  });

  const { mutate: rewardMutation, isPending: rewardPending } = useMutation<
    ScanRewardRequestCodeResponse,
    AxiosError,
    ScanRewardRequestCodeCommand
  >({
    mutationFn: (values) => scanRewardRequestCode(getTenantId() || "", values),
    onSuccess: async (data) => {
      setUpperAlertData({
        variant: "rewardScan",
        points: data.pointsTaken,
      });
      setBottomAlertPoints(data.pointsLeft);
      form.setFieldValue("code", "");
    },
    onError: async (data) => {
      setUpperAlertData({
        variant: "error",
        description: formatErrorMessage(
          data as AxiosError<HttpValidationProblemDetails>
        ),
      });
    },
  });

  const form = useForm<CodeFormValues, any>({
    defaultValues: {
      type: "reward",
      code: "",
    },
    onSubmit: async ({ value }) => {
      if (value.type === "points")
        pointMutation({ customerPublicId: value.code.trim() });
      else if (value.type === "reward")
        rewardMutation({ rewardRequestCode: value.code.trim() });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Box
        sx={{
          minHeight:
            isSafari && isStandalone
              ? "100svh"
              : "calc(100svh - env(safe-area-inset-bottom, 0))",
          backgroundColor: "#040104",
        }}
      >
        <TopBar
          linkHref={cmsRoutes.scanner.base}
          middleItem={<Logo theme="dark" type="mini" />}
        />
        <Box
          sx={{
            px: 3,
            position: "relative",
          }}
        >
          {upperAlertData && (
            <UpperAlert
              {...upperAlertData}
              onClose={() => setUpperAlertData(null)}
              textPage
            />
          )}
          <Typography
            variant="h5"
            fontWeight={700}
            mb={1}
            mt={5}
            sx={{
              color: (theme) => theme.palette.common.white,
            }}
          >
            {t("employeeScan.textPage.header")}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
            mb={6}
          >
            {t("employeeScan.textPage.description")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            <form.Subscribe
              selector={(state) => [state.values.type]}
              children={([selectedType]) => (
                <ButtonGroup
                  variant="contained"
                  size="large"
                  sx={{
                    "& .MuiButton-root": {
                      fontWeight: 700,
                      letterSpacing: "0.46px",
                      mb: 2,
                    },
                  }}
                  fullWidth
                >
                  {codeTypes.map((type) => (
                    <Button
                      key={type}
                      sx={[
                        {
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        },
                        type !== selectedType
                          ? {
                              opacity: 0.7,
                            }
                          : {
                              opacity: 1,
                            },
                      ]}
                      onClick={(e) => {
                        form.setFieldValue("type", type);
                        e.currentTarget.blur();
                      }}
                    >
                      {t(`employeeScan.textPage.form.type.${type}`)}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            />
            <CodeTextInput
              form={form}
              name="code"
              label={t("employeeScan.textPage.form.code")}
              required
              validators={{
                onChange: codeValidator,
              }}
            />
            <form.Subscribe
              selector={(state) => [state.values.code, state.isValid]}
              children={([code, isValid]) => (
                <LoadingButton
                  text={t("employeeScan.textPage.form.submit")}
                  isLoading={pointPending || rewardPending}
                  sx={{
                    mt: 6,
                  }}
                  disabled={!code || code === "____-____" || !isValid}
                />
              )}
            />
          </form>
          {bottomAlertPoints !== null && (
            <BottomAlert
              points={bottomAlertPoints}
              onClose={() => setBottomAlertPoints(null)}
              textPage
            />
          )}
        </Box>
      </Box>
    </Container>
  );
}
