import { PaginationParams } from "../../types";
import { RewardRequestParams, RewardsListParams } from "./companiesService";

const basePath = "/customer/api/v1";

export const customerApiPaths = {
  auth: {
    base: `${basePath}/auth`,
    info: `${basePath}/auth/info`,
    login: `${basePath}/auth/login`,
    register: `${basePath}/auth/register`,
    refresh: `${basePath}/auth/refresh`,
    confirmEmail: `${basePath}/auth/confirm-email`,
    resendConfirmationEmail: `${basePath}/auth/resendConfirmationEmail`,
    forgotPassword: `${basePath}/auth/forgot-password`,
    resetPassword: `${basePath}/auth/reset-password`,
  },
  companies: {
    rewardsList: (params: RewardsListParams) =>
      `${basePath}/companies/${params.identifier}/rewards`,
    rewardRequest: (params: RewardRequestParams) =>
      `${basePath}/companies/${params.identifier}/reward-requests/${params.rewardId}`,
    scanSummary: `${basePath}/companies/scans/latest`,
    points: ({ pageParam }: PaginationParams, searchKey?: string) =>
      `${basePath}/companies/points?pageNumber=${pageParam.pageNumber}&pageSize=${pageParam.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}`,
    scans: ({ pageParam }: PaginationParams, searchKey?: string) =>
      `${basePath}/companies/scans?pageNumber=${pageParam.pageNumber}&pageSize=${pageParam.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}`,
    locations: `${basePath}/companies/locations`,
  },
  customers: {
    code: `${basePath}/customers/code`,
  },
  externalAuth: {
    signIn: `${basePath}/external-auth/sign-in`,
    callback: `${basePath}/external-auth/callback`,
  },
  wallets: {
    apple: `${basePath}/wallets/apple-pass`,
    google: `${basePath}/wallets/google-pass`,
  },
  notifications: {
    setFCMToken: `${basePath}/notifications/setToken`,
    invalidateFCMToken: `${basePath}/notifications/invalidateToken`,
    allowNotifications: `${basePath}/notifications/allowNotifications`,
    notificationConsent: `${basePath}/notifications/notificationConsent`,
    subscribe: (companyIdentifier: string) =>
      `${basePath}/notifications/${companyIdentifier}/subscribe`,
    unsubscribe: (companyIdentifier: string) =>
      `${basePath}/notifications/${companyIdentifier}/unsubscribe`,
    subscriptions: `${basePath}/notifications/subscriptions`,
  },
};

export const customerApiSocketPaths = {
  gainPoints: "gainPoints",
  claimReward: "claimReward",
};
