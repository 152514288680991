"use client";

import { ButtonProps, Button as MuiButton } from "@mui/material";

export const ErrorButton = ({ ...props }: ButtonProps) => {
  return (
    (<MuiButton
      {...props}
      color="error"
      sx={theme => ({
        "&:hover": {
          backgroundColor: theme.palette.error.dark,
        },
        "&:active": {
          backgroundColor: theme.palette.error.dark,
        }
      })}
    >
      {props.children}
    </MuiButton>)
  );
};
