"use client";

import { useContext, useEffect } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getPaymentMethod } from "@repo/api-config/services/company";
import { getTenantId } from "@repo/api-config";
import { CardInfo } from "./CardInfo";
import { useServerErrorFormatter } from "@repo/utils";
import { AxiosError } from "axios";
import { ErrorMessage } from "../ErrorMessage";

export const DashboardCreditCardInfo = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const tenantId = getTenantId();
  const { errorMessage, setError } = useServerErrorFormatter();
  const { data, isLoading, error } = useQuery({
    queryFn: () => getPaymentMethod(tenantId!),
    queryKey: ["PaymentMethod"],
    enabled: !!tenantId,
  });
  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (errorMessage && !data)
    return <ErrorMessage errorMessage={errorMessage} />;
  return (
    <Box>
      <Typography variant="h6" fontWeight={700} sx={{ mb: 1 }}>
        {t("DashboardCreditCardInfo.yourCard")}
      </Typography>
      {data && <CardInfo data={data} />}
    </Box>
  );
};
