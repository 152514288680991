"use client";

import { ButtonProps, Button as MuiButton } from "@mui/material";

export const TransparentButton = ({ ...props }: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      sx={[
        (theme) => ({
          backgroundColor: "transparent",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "rgba(224, 224, 224, 0.8)",
          },
          "&:active": {
            backgroundColor: "rgba(224, 224, 224, 0.4)",
          },
          ...theme.applyStyles("dark", {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: "rgba(224, 224, 224, 0.2)",
            },
            "&:active": {
              backgroundColor: "rgba(224, 224, 224, 0.2)",
            },
          }),
        }),
        ,
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {props.children}
    </MuiButton>
  );
};
