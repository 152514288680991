import { Error } from "@mui/icons-material";
import { Box, Card, CircularProgress, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";

interface DashboardInfoCardProps {
  title: string;
  content?: string | number;
  icon: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
}

export const DashboardInfoCard = ({
  title,
  content,
  icon,
  isError,
  isLoading,
}: DashboardInfoCardProps) => {
  return (
    (<Card
      sx={{
        p: 2,
        borderRadius: 4,
      }}
      elevation={4}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Paper
          elevation={24}
          sx={{
            width: 56,
            height: 56,
            boxShadow: "none",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        >
          {icon}
        </Paper>
        <Box>
          <Typography variant="body2" sx={{ mb: 0.25 }}>
            {title}
          </Typography>
          {!isLoading && !isError && (
            <Typography variant="h5" fontWeight={700}>
              {content}
            </Typography>
          )}
          {isLoading && <CircularProgress size={28} />}
          {isError && (
            <Error
              sx={theme => ({
                height: 28,
                width: 28,
                fill: theme.palette.error.main
              })}
            />
          )}
        </Box>
      </Box>
    </Card>)
  );
};
