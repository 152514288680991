import { CloseOutlined } from "@mui/icons-material";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Button, TransparentButton } from "@repo/ui";
import { CodeType } from "./scanner";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

export interface ConfirmModalProps {
  type?: CodeType;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({
  open,
  type,
  onCancel,
  onConfirm,
}: ConfirmModalProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const texts = {
    reward: t("employeeScan.confirmModal.texts.reward"),
    customer: t("employeeScan.confirmModal.texts.customer"),
  };
  return (
    <Modal open={open}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 342,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 1.5,
            pl: 3,
            pr: 2,
            pb: 2,
          }}
        >
          <Typography variant="h6">
            {t("employeeScan.confirmModal.header")}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 3,
            pb: 2.5,
          }}
        >
          {type && <Typography>{texts[type]}</Typography>}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 1,
            gap: 1,
          }}
        >
          <TransparentButton onClick={onCancel}>
            {t("employeeScan.confirmModal.cancel")}
          </TransparentButton>
          <Button onClick={onConfirm}>
            {t("employeeScan.confirmModal.confirm")}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
