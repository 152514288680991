export const setCookie = (name: string, value: string, ms: number) => {
  const date = new Date();
  date.setTime(date.getTime() + ms);
  const expires = `expires=${date.toUTCString()}`;
  const isLocal =
    window.location.hostname === "localhost" ||
    /^[0-9.]+$/.test(window.location.hostname);
  const secureFlag = isLocal ? "" : "Secure";
  document.cookie = `${name}=${value}; ${expires}; path=/; ${secureFlag};`;
};

export const getCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const cookie = cookies.find((cookie) => cookie.startsWith(nameEQ));
  return cookie ? cookie.substring(nameEQ.length) : null;
};

export const eraseCookie = (name: string) => {
  const isLocal =
    window.location.hostname === "localhost" ||
    /^[0-9.]+$/.test(window.location.hostname);
  const secureFlag = isLocal ? "" : "Secure";
  document.cookie = `${name}=; Max-Age=-99999999; path=/; ${secureFlag};`;
};
