import { Box, FormGroup } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { addReward, editReward } from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  AddRewardCommand,
  EditRewardCommand,
} from "@repo/types/companyApi.types";
import { Button, Card, ErrorMessage, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  getCookie,
  requiredNumberValidator,
  rewardDescriptionValidator,
  rewardNameValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { FormApi, useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext } from "react";

type NewRewardFormData = Omit<AddRewardCommand, "pointCost"> & {
  pointCost: number | "";
};

export const NewReward = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const tenantId = getTenantId();
  const isPendingOwner = getCookie("loymeeUserRole") === "PendingOwner";

  const { mutate: addRewardMutation } = useMutation<
    void,
    AxiosError,
    AddRewardCommand
  >({
    mutationFn: (values) => addReward(tenantId!, values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Rewards.rewardAdded"));
      navigate({
        to: isPendingOwner
          ? `${cmsRoutes.rewards.base}?pending=true`
          : cmsRoutes.rewards.base,
      });
    },
  });

  const form = useForm<NewRewardFormData, any>({
    defaultValues: {
      name: "",
      description: "",
      pointCost: "",
    },
    onSubmit: ({ value }) => {
      addRewardMutation(value as AddRewardCommand);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Rewards.addReward")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <RewardForm form={form} errorMessage={errorMessage} />
    </Card>
  );
};

export const EditReward = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const loaderData = useLoaderData({
    from: "/_private/_withDashboard/rewards/edit/$rewardId",
  });
  const { errorMessage, setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const { showNotification } = useNotificationsContext();
  const tenantId = getTenantId();

  const { mutate: editRewardMutation } = useMutation<
    void,
    AxiosError,
    EditRewardCommand
  >({
    mutationFn: (values) => editReward(tenantId!, values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Rewards.rewardEdited"));
      navigate({ to: cmsRoutes.rewards.base });
    },
  });

  const form = useForm<EditRewardCommand, any>({
    defaultValues: {
      id: loaderData.id,
      name: loaderData.name || "",
      description: loaderData.description || "",
      pointCost: loaderData.pointCost,
    },
    onSubmit: ({ value }) => {
      editRewardMutation({
        id: loaderData.id,
        description: value.description,
        pointCost: value.pointCost,
        name: value.name,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Rewards.editReward")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <RewardForm form={form} errorMessage={errorMessage} />
    </Card>
  );
};

interface RewardFormProps {
  form: FormApi<any, any>;
  errorMessage?: string;
}

const RewardForm = ({ form, errorMessage }: RewardFormProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <FormGroup sx={{ gap: 2 }}>
          <TextInput
            form={form}
            name="name"
            validators={{
              onChange: rewardNameValidator,
            }}
            label={t("Rewards.form.name")}
            required
            charLimit={24}
          />
          <TextInput
            form={form}
            name="description"
            multiline
            validators={{
              onChange: rewardDescriptionValidator,
            }}
            label={t("Rewards.form.description")}
            charLimit={100}
          />
          <TextInput
            form={form}
            type="number"
            name="pointCost"
            validators={{
              onChange: requiredNumberValidator,
            }}
            label={t("Rewards.form.pointCost")}
            required
          />
        </FormGroup>
        {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
        <form.Subscribe
          selector={(state) => state.isValid && !state.isPristine}
          children={(isValid) => (
            <Button
              type="submit"
              sx={{ mt: 4, alignSelf: "flex-end" }}
              variant="contained"
              disabled={!isValid}
            >
              {t("Rewards.form.save")}
            </Button>
          )}
        />
      </Box>
    </Box>
  );
};
