export const webAppRoutes = {
  login: {
    base: "/login",
  },
  register: {
    base: "/register",
  },
  forgotPassword: {
    base: "/forgot-password",
  },
  resetPassword: {
    base: "/reset-password",
  },
  user: {
    changeEmail: "/user/change-email",
    changePassword: "/user/change-password",
    home: "/user/home",
    profile: "/user/profile",
    points: "/user/points",
    installPwa: "/user/install-pwa",
    places: "/user/places",
    notifications: "/user/notifications",
    payment: "/user/payment",
    payments: "/user/payments",
    rewards: "/user/rewards",
    rewardQr: "/user/reward-qr",
    theme: "/user/theme",
  },
  callbackAppleFe: {
    base: "/callback-apple-fe",
  },
} as const;

export const cmsRoutes = {
  login: {
    base: "/login",
  },
  scanner: {
    base: "/scanner",
    text: "/scanner/text",
  },
  register: {
    company: "/register/company",
  },
  forgotPassword: {
    base: "/forgot-password",
  },
  resetPassword: {
    base: "/reset-password",
  },
  confirmEmail: {
    base: "/confirm-email",
  },
  confirmEmailChangePassword: {
    base: "/confirm-email-change-password",
  },
  home: {
    base: "/",
  },
  customers: {
    base: "/customers",
  },
  notifications: {
    base: "/notifications",
  },
  mailNotifications: {
    base: "/mail-notifications",
  },
  employees: {
    base: "/employees",
    new: "/employees/new",
    edit: (id: string) => `/employees/edit/${id}`,
  },
  locations: {
    base: "/locations",
    new: "/locations/new",
  },
  rewards: {
    base: "/rewards",
    new: "/rewards/new",
    edit: (id: string) => `/rewards/edit/${id}`,
  },
  settings: {
    base: "/settings",
  },
  sentryTest: {
    base: "/sentry-test",
  },
  invoices: {
    base: "/invoices",
  },
} as const;

export const superAdminRoutes = {
  login: {
    base: "/login",
  },
  confirmEmail: {
    base: "/confirm-email",
  },
  forgotPassword: {
    base: "/forgot-password",
  },
  resetPassword: {
    base: "/reset-password",
  },
  home: {
    base: "/",
  },
  companies: {
    base: "/companies",
    new: "/companies/new",
  },
  subscriptions: {
    base: "/subscriptions",
  },
  plans: {
    base: "/plans",
    new: "/plans/new",
  },
  sentryTest: {
    base: "/sentry-test",
  },
  agents: {
    base: "/agents",
    new: "/agents/new",
  },
} as const;

type ExtractRoutePaths<T> = T extends { [key: string]: infer U }
  ? U extends string
    ? U
    : ExtractRoutePaths<U>
  : never;

export type AppRouteValues =
  | ExtractRoutePaths<typeof webAppRoutes>
  | ExtractRoutePaths<typeof superAdminRoutes>
  | ExtractRoutePaths<typeof cmsRoutes>;
