import { Box, Typography, Link as MuiLink } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { getCurrentSubscription } from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { SubscriptionState } from "@repo/types/companyApi.types";
import { cmsRoutes, usePlural } from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { useContext, useMemo } from "react";

export const DashboardStatusBanner = () => {
  const tenantId = getTenantId();
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const { data: currentSubscription } = useQuery({
    queryKey: ["currentSubscription"],
    queryFn: () => getCurrentSubscription(tenantId!),
    refetchOnMount: true,
  });

  const pluralRemaining = usePlural([
    t("DashboardStatusBanner.trial.remaining.1"),
    t("DashboardStatusBanner.trial.remaining.2"),
    t("DashboardStatusBanner.trial.remaining.3"),
  ]);
  const pluralDays = usePlural([
    t("DashboardStatusBanner.trial.days.1"),
    t("DashboardStatusBanner.trial.days.2"),
    t("DashboardStatusBanner.trial.days.3"),
  ]);

  const remainingDays = useMemo(() => {
    if (
      !currentSubscription ||
      currentSubscription.state !== SubscriptionState.Trial
    )
      return null;
    const endDate = new Date(currentSubscription.currentPeriodEnd).getTime();
    const now = Date.now();
    const diffInMs = endDate - now;
    const remainingDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return remainingDays;
  }, [currentSubscription]);

  if (currentSubscription?.state === SubscriptionState.Cancelled)
    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.error.dark,
          px: 1,
          py: 0.25,
          borderRadius: 2,
        }}
      >
        <Typography
          textAlign="center"
          sx={{ color: (theme) => theme.palette.common.white }}
        >
          ⚠️ {t("DashboardStatusBanner.canceled.infoStart")}{" "}
          <MuiLink
            component={Link}
            color="inherit"
            to={cmsRoutes.settings.base}
            sx={{
              textDecoration: "underline !important",
            }}
          >
            {t("DashboardStatusBanner.canceled.paymentSettings")}
          </MuiLink>{" "}
          {t("DashboardStatusBanner.canceled.toBuyAgain")}
        </Typography>
      </Box>
    );

  if (currentSubscription?.state === SubscriptionState.Expired)
    return (
      (<Box
        sx={theme => ({
          width: "100%",
          backgroundColor: theme.palette.error.dark,
          px: 1,
          py: 0.25,
          borderRadius: 2
        })}
      >
        <Typography
          textAlign="center"
          sx={theme => ({
            color: theme.palette.common.white
          })}
        >
          ⚠️ {t("DashboardStatusBanner.expired.infoStart")}{" "}
          <MuiLink
            component={Link}
            color="inherit"
            to={cmsRoutes.settings.base}
            sx={{
              textDecoration: "underline !important",
            }}
          >
            {t("DashboardStatusBanner.expired.checkCard")}
          </MuiLink>{" "}
          {t("DashboardStatusBanner.expired.or")}{" "}
          <MuiLink
            color="inherit"
            href="mailto:support@loymee.com"
            sx={{
              textDecoration: "underline !important",
            }}
          >
            {t("DashboardStatusBanner.expired.contactUs")}
          </MuiLink>{" "}
          {t("DashboardStatusBanner.expired.toPay")}
        </Typography>
      </Box>)
    );

  if (remainingDays)
    return (
      (<Box
        sx={theme => ({
          width: "100%",
          backgroundColor: theme.palette.secondary.main,
          px: 1,
          py: 0.25,
          borderRadius: 2
        })}
      >
        <Typography
          textAlign="center"
          sx={theme => ({
            color: theme.palette.common.black
          })}
        >
          ⚡️ {t("DashboardStatusBanner.trial.infoStart")}{" "}
          {pluralRemaining(remainingDays)} {remainingDays}{" "}
          {pluralDays(remainingDays)}.
        </Typography>
        <Typography
          textAlign="center"
          sx={theme => ({
            color: theme.palette.common.black
          })}
        >
          {t("DashboardStatusBanner.trial.toContinue")}{" "}
          <MuiLink
            component={Link}
            color="inherit"
            to={cmsRoutes.settings.base}
            sx={{
              textDecoration: "underline !important",
            }}
          >
            {t("DashboardStatusBanner.trial.fillData")}
          </MuiLink>
        </Typography>
      </Box>)
    );
  return null;
};
