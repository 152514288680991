"use client";

import { CircularProgress, Stack } from "@mui/material";

type Props = {
  withBottomBar?: boolean;
};

export const Loader = ({ withBottomBar }: Props) => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      height={
        withBottomBar
          ? (theme) =>
              `calc(100svh - ${theme.spacing(10)} - (env(safe-area-inset-bottom, 0) / 2))`
          : "100svh"
      }
    >
      <CircularProgress />
    </Stack>
  );
};
