import ReactDOM from "react-dom/client";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import {
  CompanyAuthProps,
  isAuthenticated,
  queryClient,
  useCompanyAuth,
} from "@repo/api-config";
import { NotificationsContextProvider, cmsRoutes } from "@repo/utils";
import { routeTree } from "./routeTree.gen.ts";
import "@fontsource/space-grotesk/400.css";
import "@fontsource/space-grotesk/500.css";
import "@fontsource/space-grotesk/700.css";
import "./index.css";
import "./i18n.ts";
import { getMuiLocale, I18nContext } from "@repo/i18n-config";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import * as locales from "@mui/material/locale";
import * as Sentry from "@sentry/react";
import { darkColorScheme, themeSettings } from "@repo/mui-config/theme.ts";

export type RouterContext = {
  auth: CompanyAuthProps;
};

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const themeWithLocale = useMemo(
    () =>
      createTheme(
        {
          ...themeSettings,
          colorSchemes: {
            light: false,
            dark: { palette: darkColorScheme },
          },
        },
        locales[getMuiLocale(i18n.language)]
      ),
    [i18n.language]
  );

  return (
    <I18nContext.Provider value={{ lang: i18n.language }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeWithLocale} defaultMode="dark">
          <CssBaseline />
          <AppContainer />
        </ThemeProvider>
      </QueryClientProvider>
    </I18nContext.Provider>
  );
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const AppContainer = () => {
  const auth = useCompanyAuth();

  const isPublicRoute =
    router.latestLocation.pathname === "/" ||
    router.latestLocation.pathname === cmsRoutes.confirmEmail.base ||
    router.latestLocation.pathname === cmsRoutes.login.base ||
    router.latestLocation.pathname === cmsRoutes.register.company ||
    router.latestLocation.pathname === cmsRoutes.forgotPassword.base ||
    router.latestLocation.pathname === cmsRoutes.resetPassword.base ||
    router.latestLocation.pathname ===
      cmsRoutes.confirmEmailChangePassword.base ||
    router.latestLocation.pathname === cmsRoutes.sentryTest.base;

  if (!isPublicRoute && !isAuthenticated()) {
    window.location.href = cmsRoutes.login.base;
  }

  return (
    <NotificationsContextProvider>
      <RouterProvider
        router={router}
        context={{
          auth,
        }}
      />
    </NotificationsContextProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
