"use client";

import { Box, Fab } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { ReactNode } from "react";
import { LinkWrapper, LinkWrapperProps } from "../LinkWrapper";
import { AppRouteValues } from "@repo/utils";
import { useRouter } from "next/navigation";

interface TopBarProps {
  middleItem?: ReactNode;
  background?: "dark" | "light";
  linkHref?: AppRouteValues;
  linkQuery?: LinkWrapperProps["query"];
  backLink?: boolean;
}

export const TopBar = ({
  middleItem,
  background,
  linkHref,
  linkQuery,
  backLink,
}: TopBarProps) => {
  const router = backLink && useRouter();
  return (
    <Box
      sx={[
        {
          pt: 7,
          px: 4,
          pb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "relative",
        },
        background === "dark"
          ? {
              backgroundColor: "primary.contrast",
            }
          : {
              backgroundColor: null,
            },
      ]}
    >
      {linkHref && (
        <LinkWrapper to={linkHref} query={linkQuery}>
          <Fab
            sx={{
              backgroundColor: "common.white",
              height: 48,
              width: 48,
              position: "absolute",
              top: 48,
              left: 24,
            }}
          >
            <ChevronLeft
              sx={(theme) => ({
                fill: theme.palette.primary.contrast,
              })}
            />
          </Fab>
        </LinkWrapper>
      )}
      {backLink && router && (
        <Fab
          sx={{
            backgroundColor: "common.white",
            height: 48,
            width: 48,
            position: "absolute",
            top: 48,
            left: 24,
          }}
          onClick={router.back}
        >
          <ChevronLeft
            sx={(theme) => ({
              fill: theme.palette.primary.contrast,
            })}
          />
        </Fab>
      )}
      {middleItem && <Box sx={{ height: 32 }}>{middleItem}</Box>}
    </Box>
  );
};
