import { createFileRoute, redirect } from "@tanstack/react-router";
import { cmsRoutes } from "@repo/utils";
import { clearUserAuth } from "@repo/api-config";
import { DashboardTemplate } from "@repo/ui";

export const Route = createFileRoute("/_private/_withDashboard")({
  component: DashboardTemplate,
  beforeLoad: ({ context }) => {
    const { isAuthenticated, getUserRole } = context.auth;
    console.log(getUserRole());
    if (getUserRole() === "Operator") {
      throw redirect({
        to: cmsRoutes.scanner.base,
      });
    }

    if (!isAuthenticated()) {
      clearUserAuth();
      throw redirect({
        to: cmsRoutes.login.base,
      });
    }
  },
});
