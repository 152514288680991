"use client";

import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

interface MapPlacesContextType {
  setSearchQuery: (query: string) => void;
  queryPlaces: google.maps.places.PlaceResult[] | null;
  selectedPlace: google.maps.places.PlaceResult | null;
  selectPlace: (place: google.maps.places.PlaceResult | null) => void;
  openInfoWindowPlaceId: string | null;
  setOpenInfoWindowPlaceId: (placeId: string | null) => void;
}

const MapPlacesContext = createContext<MapPlacesContextType | undefined>(
  undefined
);

export const MapPlacesProvider = ({ children }: { children: any }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [queryPlaces, setQueryPlaces] = useState<
    google.maps.places.PlaceResult[] | null
  >(null);
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [openInfoWindowPlaceId, setOpenInfoWindowPlaceId] = useState<
    string | null
  >(null);

  const map = useMap();
  const placesLib = useMapsLibrary("places");

  const svc = useMemo(() => {
    if (!map || !placesLib) return null;
    return new placesLib.PlacesService(map);
  }, [map, placesLib]);

  const formatAndSetQueryPlaces = (
    places: google.maps.places.PlaceResult[]
  ) => {
    setQueryPlaces(
      places.filter(
        (place, index, self) =>
          index === self.findIndex((p) => p.place_id === place.place_id)
      )
    );
  };

  const searchGooglePlaces = useCallback(() => {
    if (!svc || !map) return;

    let newPlaces: google.maps.places.PlaceResult[] = [];
    svc.textSearch({ query: searchQuery }, (places) => {
      if (places) newPlaces = [...places];
      if (searchQuery.match(/^-?\d{1,2}\.\d+,\s?-?\d{1,3}\.\d+$/)) {
        svc.nearbySearch(
          {
            location: new google.maps.LatLng(
              Number(searchQuery.split(",")[0]),
              Number(searchQuery.split(",")[1])
            ),
            radius: 200,
          },
          (places) => {
            if (places) newPlaces = [...newPlaces, ...places];
            formatAndSetQueryPlaces(newPlaces);
          }
        );
      } else {
        const addressPlace = places?.find(
          (place) =>
            place.types?.includes("premise") ||
            place.types?.includes("street_address") ||
            place.types?.includes("route") ||
            place.types?.includes("park") ||
            place.types?.includes("locality")
        );
        if (addressPlace && addressPlace.geometry?.location) {
          map.setCenter(addressPlace.geometry.location);
          map.setZoom(15);
          svc.nearbySearch(
            {
              location: new google.maps.LatLng(
                addressPlace.geometry?.location?.lat(),
                addressPlace.geometry?.location?.lng()
              ),
              radius: 150,
            },
            (places) => {
              if (places)
                newPlaces = [
                  ...newPlaces,
                  ...places.filter((p) =>
                    p.types?.includes("point_of_interest")
                  ),
                ];
              formatAndSetQueryPlaces(newPlaces);
            }
          );
        } else {
          const foundPlace = places && places[0];
          if (foundPlace?.geometry?.location) {
            map.setCenter(foundPlace.geometry.location);
            map.setZoom(15);
          }
          formatAndSetQueryPlaces(newPlaces);
        }
      }
    });
  }, [svc, map, searchQuery]);

  const selectPlace = (place: google.maps.places.PlaceResult | null) => {
    if (!map) return;

    setSelectedPlace(place);
    if (place?.geometry?.location) {
      map.setCenter(place.geometry.location);
      map.setZoom(15);
    }
  };

  useEffect(() => {
    if (searchQuery) searchGooglePlaces();
  }, [searchQuery]);

  const value = {
    setSearchQuery,
    queryPlaces,
    selectedPlace,
    selectPlace,
    openInfoWindowPlaceId,
    setOpenInfoWindowPlaceId,
  };

  return (
    <MapPlacesContext.Provider value={value}>
      {children}
    </MapPlacesContext.Provider>
  );
};

export const useMapPlacesContext = (): MapPlacesContextType => {
  const context = useContext(MapPlacesContext);
  if (!context) {
    throw new Error(
      "useMapPlacesContext must be used within a MapPlacesProvider"
    );
  }
  return context;
};
