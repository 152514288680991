import { getCookie, setCookie, eraseCookie } from "@repo/utils";
import { AuthResponse } from "../types";

export const handleAuthResponse = (
  response: any,
  additionalCookies = {},
  saveLogin = true
): Promise<AuthResponse> => {
  return new Promise<AuthResponse>((resolve, reject) => {
    if (!response) {
      clearUserAuth();
      reject();
    }

    const expiration =
      response.accessTokenResponse?.expiresIn || response.expiresIn;

    const accessToken =
      response.accessTokenResponse?.accessToken || response.accessToken;

    const refreshToken =
      response.accessTokenResponse?.refreshToken || response.refreshToken;

    if (accessToken) {
      setCookie(
        "loymeeAccessToken",
        accessToken,
        expiration * 1000 // as in API in seconds
      );
    }

    if (response.identifier) {
      setCookie(
        "loymeeTenantId",
        response.identifier,
        14 * 24 * 60 * 60 * 1000 // 14 days
      );
    }

    if (refreshToken && saveLogin) {
      setCookie(
        "loymeeRefreshToken",
        refreshToken,
        14 * 24 * 60 * 60 * 1000 // 14 days
      );
    }

    for (const [key, value] of Object.entries(additionalCookies)) {
      setCookie(key, value as string, expiration * 1000);
    }

    resolve({
      tenantId: response.identifier,
    });
  });
};

export const updateCompanyAuthAfterConfirmation = (response: any) => {
  return new Promise<void>((resolve, reject) => {
    if (!response) {
      clearUserAuth();
      reject();
    }

    if (response.accessToken) {
      setCookie(
        "loymeeAccessToken",
        response.accessToken,
        response.expiresIn * 1000 // as in API in seconds
      );
    }

    if (response.refreshToken && getCookie("loymeeRefreshToken")) {
      setCookie(
        "loymeeRefreshToken",
        response.refreshToken,
        14 * 24 * 60 * 60 * 1000 // 14 days
      );
    }
    setCookie("loymeeUserRole", "Owner", 14 * 24 * 60 * 60 * 1000);
    resolve();
  });
};

export const clearUserAuth = (): Promise<void> => {
  return new Promise((resolve) => {
    eraseCookie("loymeeAccessToken");
    eraseCookie("loymeeRefreshToken");
    eraseCookie("loymeeTenantId");
    eraseCookie("loymeeUserRole");
    resolve();
  });
};

export const getTenantId = (): string | null => {
  return getCookie("loymeeTenantId");
};

export const getStoredToken = (): string | null => {
  return getCookie("loymeeAccessToken");
};

export const getStoredRefreshToken = (): string | null => {
  return getCookie("loymeeRefreshToken");
};

export const isAuthenticated = (): boolean => {
  return (
    getCookie("loymeeAccessToken") !== null ||
    getCookie("loymeeRefreshToken") !== null
  );
};
