import { Card } from "@mui/material";
import {
  DashboardBilling,
  DashboardCreditCardInfo,
  DashboardPaymentList,
  DashboardPlanInfo,
} from "@repo/ui";

export const DashboardSidebar = () => {
  return (
    (<Card
      sx={theme => ({
        p: { xs: 2, md: 3 },
        borderRadius: 4,
        mt: { lg: -5 },
        minHeight: `calc(100svh - ${theme.spacing(8)})`,
        display: "flex",
        flexDirection: "column",
        gap: 4
      })}
      elevation={4}
    >
      <DashboardPlanInfo />
      <DashboardCreditCardInfo />
      <DashboardBilling />
      <DashboardPaymentList />
    </Card>)
  );
};
