import { Add } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  deleteLocation,
  getFlags,
  getLocationsList,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { ConfirmModal, LinkWrapper, SimpleDataTable } from "@repo/ui";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DeletedItem } from "../../@types";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { fetchGoogleDetails } from "@repo/ui/src/maps/googleApi";

export const LocationsPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId() || "";
  const [isInitialized, setIsInitialized] = useState(false);
  const placesApi = useMapsLibrary("places");
  const attributionDiv = useRef<HTMLDivElement>(null);
  const { state } = useRouter();
  const [pendingOwnerModalOpen, setPendingOwnerModalOpen] = useState(false);

  const { showNotification } = useNotificationsContext();
  const {
    data: locationsList,
    isLoading,
    refetch: refetchLocationsList,
  } = useQuery({
    queryFn: () => getLocationsList(tenantId),
    queryKey: ["locations"],
    refetchOnMount: true,
  });

  useEffect(() => {
    if (state.location.searchStr.includes("?pending=true")) {
      setPendingOwnerModalOpen(true);
    }
  }, []);

  const {
    data: flags,
    refetch: refetchFlags,
    isFetching: flagsFetching,
  } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantId!),
    refetchOnMount: true,
  });

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const svc = useMemo(() => {
    if (!isInitialized) return null;
    if (!placesApi || !attributionDiv.current) return null;
    return new placesApi.PlacesService(attributionDiv.current);
  }, [placesApi, isInitialized]);

  const {
    data: placesListWithGoogleData,
    isLoading: isGoogleDataLoading,
    refetch: refetchGoogleData,
  } = useQuery({
    queryFn: () =>
      fetchGoogleDetails(svc!, locationsList!, lang).then((data) =>
        data.map((d) => ({
          id: d.internalId!,
          formattedAddress: d.formattedAddress,
        }))
      ),
    queryKey: ["PlacesGoogleData"],
    enabled: !!svc && !!locationsList,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (placesListWithGoogleData) refetchGoogleData();
  }, [locationsList]);

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const {
    mutate: deleteLocationMutation,
    isPending: isDeleteLocationMutationPending,
  } = useMutation<any, AxiosError, any>({
    mutationFn: (id) => deleteLocation(tenantId!, id),
    onSuccess: () => {
      showNotification(t("Locations.locationDeleted"));
      refetchLocationsList();
      refetchFlags();
    },
    onError: () => {
      showNotification(t("Locations.locationDeleteError"), {
        type: "error",
      });
    },
  });

  return (
    <Box>
      {locationsList && (
        <Typography variant="h6">
          {t("Locations.yourLocations")}: {locationsList?.length}/
          {flags?.locationsLimit ?? 1}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: -8.5,
          mb: 6,
        }}
      >
        <Button
          component={Link}
          to={cmsRoutes.locations.new}
          size="large"
          sx={{ pl: 2 }}
          disabled={
            flags?.locationsLimitReached ??
            (locationsList && locationsList.length > 1)
          }
        >
          <Add sx={{ mr: 1 }} />
          {t("Locations.addLocation")}
        </Button>
      </Box>
      <SimpleDataTable
        data={placesListWithGoogleData}
        isLoading={isLoading || isGoogleDataLoading}
        showActions={{
          handleDelete: (id) => {
            setDeletedItem({
              id,
              name: placesListWithGoogleData?.find((i) => i.id === id)
                ?.formattedAddress!,
            });
          },
          isDeleteMutationPending: isDeleteLocationMutationPending,
        }}
      />
      <ConfirmModal
        open={!!deletedItem?.id}
        handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
        handleYes={() => {
          setDeletedItem({ ...deletedItem!, id: "" });
          deleteLocationMutation(deletedItem!.id);
        }}
        text={t("Locations.locationDeleteConfirm", { name: deletedItem?.name })}
      />
      <div ref={attributionDiv} />
      <Dialog
        open={pendingOwnerModalOpen && !flagsFetching}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {t("Locations.pendingOwnerModal.content")}{" "}
          {t(
            `Locations.pendingOwnerModal.canAddMore.${!flags?.locationsLimitReached}`
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 1 }}>
            <LinkWrapper to={cmsRoutes.home.base}>
              <Button onClick={() => setPendingOwnerModalOpen(false)}>
                {t("Locations.pendingOwnerModal.continue")}
              </Button>
            </LinkWrapper>
            {!flags?.locationsLimitReached && (
              <Button
                variant="text"
                onClick={() => setPendingOwnerModalOpen(false)}
              >
                {t("Locations.pendingOwnerModal.next")}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
