import {
  handleAuthResponse,
  clearUserAuth,
  isAuthenticated,
} from "./authUtils";
import { signIn as signInService } from "../services/company";
import { getCookie } from "@repo/utils";
import { useQueryClient } from "@tanstack/react-query";
import { LoginRequest } from "@repo/types/companyApi.types";
import { AuthResponse } from "../types";

export interface CompanyAuthProps {
  signIn: (props: LoginRequest) => Promise<AuthResponse>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
  getUserRole: () => string | null;
}

export const useCompanyAuth = (): CompanyAuthProps => {
  const queryClient = useQueryClient();

  const signIn = async ({
    email,
    password,
  }: LoginRequest): Promise<AuthResponse> => {
    const response = await signInService({
      email,
      password,
    });
    return handleAuthResponse(response, undefined, true);
  };

  const getUserRole = () => {
    return getCookie("loymeeUserRole");
  };

  const logout = async (): Promise<void> => {
    queryClient.clear();
    clearUserAuth();
  };

  return {
    signIn,
    logout,
    isAuthenticated,
    getUserRole,
  };
};
