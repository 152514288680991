export interface PaginationParams {
  pageParam: {
    pageNumber: number;
    pageSize: number;
  };
}

export const statisticsChartTimePeriods = ["Week", "Month", "Year"] as const;
export type StatisticsChartTimePeriod =
  (typeof statisticsChartTimePeriods)[number];

export type EmployeeRole = "OPERATOR" | "PENDINGOPERATOR";

export type AuthResponse = {
  tenantId?: string;
};
