"use client";

import { ButtonProps, Button as MuiButton } from "@mui/material";

export const GreyButton = ({ ...props }: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      sx={[
        (theme) => ({
          backgroundColor: "grey.300",
          boxShadow: "none",
          "&.MuiButton-text:hover": {
            backgroundColor: "rgba(224, 224, 224, 0.8)",
          },
          "&.MuiButton-text:focus": {
            backgroundColor: "rgba(224, 224, 224, 0.8)",
          },
          "&.MuiButton-text:active": {
            backgroundColor: "rgba(224, 224, 224, 0.8)",
          },
          ...theme.applyStyles("dark", {
            color: `${theme.palette.common.black} !important`,
          }),
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {props.children}
    </MuiButton>
  );
};
