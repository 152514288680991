"use client";

import { useContext, useEffect, useMemo, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { alpha, Box, Typography } from "@mui/material";
import { Button } from "@repo/ui";
import { BottomAlert, UpperAlert, UpperAlertProps } from "./alerts";
import { ConfirmModal } from "./confirmModal";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  HttpValidationProblemDetails,
  ScanCustomerPublicIdCommand,
  ScanCustomerPublicIdResponse,
  ScanRewardRequestCodeCommand,
  ScanRewardRequestCodeResponse,
} from "@repo/types/companyApi.types";
import {
  scanCustomerCode,
  scanRewardRequestCode,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { getTenantId } from "@repo/api-config";
import { useServerErrorFormatter } from "@repo/utils";

export type CodeType = "reward" | "customer";

interface EmployeeQrScannerProps {
  smallScreen?: boolean;
}

export const EmployeeQrScanner = ({ smallScreen }: EmployeeQrScannerProps) => {
  const [reader, setReader] = useState<Html5Qrcode>();
  const [initializationFail, setInitializationFail] = useState(false);
  const { formatErrorMessage } = useServerErrorFormatter();

  const qrConfig = useMemo(
    () => ({
      fps: 10,
      qrbox: {
        width: smallScreen ? 180 : 256,
        height: smallScreen ? 180 : 256,
      },
      aspectRatio: 1,
    }),
    [smallScreen]
  );

  const readerStyles = useMemo(
    () => ({
      width: smallScreen ? "180px" : "256px",
      height: smallScreen ? "180px" : "256px",
    }),
    [smallScreen]
  );

  const [upperAlertData, setUpperAlertData] = useState<Omit<
    UpperAlertProps,
    "onClose"
  > | null>(null);
  useEffect(() => {
    if (upperAlertData !== null) {
      const timer = setTimeout(() => {
        setUpperAlertData(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [upperAlertData]);

  const [bottomAlertPoints, setBottomAlertPoints] = useState<number | null>(
    null
  );
  useEffect(() => {
    if (bottomAlertPoints !== null) {
      const timer = setTimeout(() => {
        setBottomAlertPoints(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [bottomAlertPoints]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<
    CodeType | undefined
  >(undefined);
  const [code, setCode] = useState("");
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { mutate: pointMutation } = useMutation<
    ScanCustomerPublicIdResponse,
    AxiosError,
    ScanCustomerPublicIdCommand
  >({
    mutationFn: (values) => scanCustomerCode(getTenantId() || "", values),
    onSuccess: async (data) => {
      if (data.userScanStreak < 3) {
        setUpperAlertData({
          variant: "pointScan",
        });
      } else {
        setUpperAlertData({
          variant: "warning",
          scanStreak: data.userScanStreak,
        });
      }
      setBottomAlertPoints(data.pointsOwned);
      startScan();
    },
    onError: async (data) => {
      setUpperAlertData({
        variant: "error",
        description: formatErrorMessage(
          data as AxiosError<HttpValidationProblemDetails>
        ),
      });
      startScan();
    },
  });

  const { mutate: rewardMutation } = useMutation<
    ScanRewardRequestCodeResponse,
    AxiosError,
    ScanRewardRequestCodeCommand
  >({
    mutationFn: (values) => scanRewardRequestCode(getTenantId() || "", values),
    onSuccess: async (data) => {
      setUpperAlertData({
        variant: "rewardScan",
        points: data.pointsTaken,
      });
      setBottomAlertPoints(data.pointsLeft);
      startScan();
    },
    onError: async (data) => {
      setUpperAlertData({
        variant: "error",
        description: formatErrorMessage(
          data as AxiosError<HttpValidationProblemDetails>
        ),
      });
      startScan();
    },
  });

  const handleStartReader = (successCallback: (text: string) => void) => {
    if (reader && !initializationFail) {
      reader
        ?.start(
          { facingMode: "environment" },
          qrConfig,
          successCallback,
          undefined
        )
        .catch(() => {
          setInitializationFail(true);
        });
    }
  };

  const qrCodeSuccessCallback = async (decodedText: string) => {
    await reader?.stop();
    reader?.clear();
    setConfirmModalOpen(true);
    setConfirmModalType(decodedText.startsWith("$R_") ? "reward" : "customer");
    setCode(decodedText.replace("$C_", "").replace("$R_", ""));
  };

  const startScan = () => {
    handleStartReader(qrCodeSuccessCallback);
  };

  const cancelScan = () => {
    setConfirmModalOpen(false);
    startScan();
  };

  const confirmScan = () => {
    setConfirmModalOpen(false);
    if (confirmModalType === "customer")
      pointMutation({
        customerPublicId: code,
      });
    else
      rewardMutation({
        rewardRequestCode: code,
      });
  };

  useEffect(() => {
    if (!initializationFail && !reader) {
      const newQrReader = new Html5Qrcode("reader");
      setReader(newQrReader);
    }
  }, [reader]);

  useEffect(() => {
    let isComponentMounted = true;

    const startTimeout = setTimeout(() => {
      if (isComponentMounted && !initializationFail && reader) {
        handleStartReader(qrCodeSuccessCallback);
      }
    });

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        reader &&
        reader.isScanning
      ) {
        reader.stop().then(() => {
          if (isComponentMounted) {
            handleStartReader(qrCodeSuccessCallback);
          }
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      isComponentMounted = false;
      clearTimeout(startTimeout);
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      if (reader && reader.isScanning) {
        reader.stop().then(() => reader.clear());
      }
    };
  }, [reader, initializationFail, smallScreen]);

  return (
    <>
      {upperAlertData && (
        <UpperAlert
          {...upperAlertData}
          onClose={() => setUpperAlertData(null)}
          smallScreen={smallScreen}
        />
      )}
      <Box
        sx={[
          {
            width: "100%",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: (theme) => alpha(theme.palette.common.white, 0.56),
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          smallScreen
            ? {
                height: 230,
              }
            : {
                height: 324,
              },
          smallScreen
            ? {
                mt: 3,
              }
            : {
                mt: 0,
              },
        ]}
      >
        {!initializationFail ? (
          <div
            id="reader"
            style={{
              overflow: "hidden",
              ...readerStyles,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 3,
            }}
          >
            <Typography sx={(theme) => ({ color: theme.palette.common.white })}>
              {t("employeeScan.scanner.noPermission")}
            </Typography>
            <Button onClick={() => window.location.reload()}>
              {t("employeeScan.scanner.refresh")}
            </Button>
          </Box>
        )}
      </Box>
      {bottomAlertPoints !== null && (
        <BottomAlert
          points={bottomAlertPoints}
          onClose={() => setBottomAlertPoints(null)}
          smallScreen={smallScreen}
        />
      )}
      <ConfirmModal
        open={confirmModalOpen}
        onCancel={cancelScan}
        onConfirm={confirmScan}
        type={confirmModalType}
      />
    </>
  );
};
