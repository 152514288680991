import { Typography, Link as MuiLink, Box } from "@mui/material";
import { Link } from "@tanstack/react-router";
import { LoginForm } from "./LoginForm";
import { Card, Logo } from "@repo/ui";
import { cmsRoutes } from "@repo/utils";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

export const LoginPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <>
      <Card fullscreen>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <Logo height={50} theme="dark" />
        </Box>
        <Typography variant="h5">{t("Login.header")}</Typography>
        <LoginForm />
        <Box display="flex" gap={1} justifyContent="space-between">
          <MuiLink component={Link} to={cmsRoutes.register.company}>
            {t("Login.createAccount")}
          </MuiLink>
          <MuiLink component={Link} to={cmsRoutes.forgotPassword.base}>
            {t("Login.forgotPassword")}
          </MuiLink>
        </Box>
      </Card>
    </>
  );
};
