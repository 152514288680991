export const usePlural = (words: [string, string, string]) => {
  const [single, two, three] = words;

  return (count: number) => {
    if (count === 0) return three;
    if (count === 1) return single;
    if (count >= 2 && count <= 4) return two;
    if (count >= 5 && count <= 21) return three;
    const singular = count % 10;
    if (singular === 0 || singular === 0 || singular >= 5) return three;
    return two;
  };
};
