import {
  CreateSubscriptionCommand,
  CustomAndPublicPlans,
  SubscriptionVm,
  UpdateSubscriptionCommand,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const buySubscription = async (
  data: CreateSubscriptionCommand,
  tenantId: string
) => {
  const response = await httpClient.post(
    companyApiPaths.subscriptions.base(tenantId),
    data
  );

  return response.data;
};

export const getCurrentSubscription = async (tenantId: string) => {
  const response = await httpClient.get<SubscriptionVm>(
    companyApiPaths.subscriptions.current(tenantId)
  );

  return response.data;
};

export const cancelSubscription = async (subId: string, tenantId: string) => {
  const response = await httpClient.delete(
    companyApiPaths.subscriptions.change(tenantId, subId)
  );

  return response.data;
};

export const cancelCancellation = async (subId: string, tenantId: string) => {
  const response = await httpClient.post(
    companyApiPaths.subscriptions.cancelCancellation(tenantId, subId)
  );

  return response.data;
};

export const changeSubscription = async (
  subId: string,
  tenantId: string,
  data: UpdateSubscriptionCommand
) => {
  const response = await httpClient.put(
    companyApiPaths.subscriptions.change(tenantId, subId),
    data
  );

  return response.data;
};

export const getPlans = async (
  tenantId: string,
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }
) => {
  const response = await httpClient.get<CustomAndPublicPlans>(
    companyApiPaths.plans.base(tenantId, pageNumber, pageSize)
  );

  return response.data;
};
