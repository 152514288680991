import {
  AccessTokenResponse,
  ForgotPasswordRequest,
  InfoRequest,
  LoginRequest,
  RefreshRequest,
  ResendConfirmationEmailRequest,
  ResetPasswordRequest,
  UserInfoResponse,
} from "@repo/types/companyApi.types";
import { companyApiPaths } from "./paths";
import { baseApiUrl, httpClient } from "../../http-client";
import axios from "axios";

export interface ResetPasswordFormRequest extends ResetPasswordRequest {
  tenantId: string;
}

export interface EmailConfirmationRequest {
  tenantId: string;
  code: string;
  changedEmail?: string;
}

export interface EmailSetPasswordConfirmationRequest {
  tenantId: string;
  resetCode: string;
  newPassword: string;
}

interface CompanyRefreshRequest extends RefreshRequest {
  tenantId: string;
}

export const signIn = async ({ email, password }: LoginRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + companyApiPaths.auth.login.slice(1),
    {
      email,
      password,
    }
  );
  return response.data;
};

export const refreshToken = async ({
  refreshToken,
  tenantId,
}: CompanyRefreshRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + companyApiPaths.auth.refresh(tenantId).slice(1),
    {
      refreshToken,
    }
  );
  return response.data;
};

export const getUserInfo = async (tenantId: string) => {
  const response = await httpClient.get<UserInfoResponse>(
    companyApiPaths.auth.info(tenantId)
  );

  return response.data;
};

export const updateUserInfo = async (
  { newPassword, oldPassword }: InfoRequest,
  tenantId: string
) => {
  const response = await httpClient.patch<AccessTokenResponse>(
    companyApiPaths.auth.info(tenantId),
    { newPassword, oldPassword }
  );

  return response.data;
};

export const forgotPassword = async ({ email }: ForgotPasswordRequest) => {
  const response = await httpClient.post(companyApiPaths.auth.forgotPassword, {
    email,
  });

  return response.data;
};

export const resetPassword = async ({
  email,
  newPassword,
  resetCode,
  tenantId,
}: ResetPasswordFormRequest) => {
  const response = await httpClient.post(
    companyApiPaths.auth.resetPassword(tenantId),
    {
      email,
      newPassword,
      resetCode,
    }
  );

  return response.data;
};

export const confirmEmail = async ({
  code,
  tenantId,
  changedEmail,
}: EmailConfirmationRequest) => {
  const response = await httpClient.post(
    companyApiPaths.auth.confirmEmail(tenantId),
    undefined,
    {
      params: {
        code,
        changedEmail,
      },
    }
  );
  return response.data;
};

export const confirmEmailSetPassword = async ({
  tenantId,
  newPassword,
  resetCode,
}: EmailSetPasswordConfirmationRequest) => {
  const response = await httpClient.post(
    companyApiPaths.auth.confirmEmailSetPassword(tenantId),
    {
      newPassword,
      resetCode,
    }
  );
  return response.data;
};

export const resendConfirmationEmail = async ({
  email,
}: ResendConfirmationEmailRequest) => {
  const response = await httpClient.post(
    companyApiPaths.auth.resendConfirmationEmail,
    {
      email,
    }
  );

  return response.data;
};
