"use client";

import { useContext, useEffect } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { cmsRoutes, useServerErrorFormatter } from "@repo/utils";
import { getInvoices } from "@repo/api-config/services/company";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorMessage } from "../ErrorMessage";
import { LinkWrapper } from "../LinkWrapper";

export const DashboardPaymentList = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const tenantId = getTenantId();
  const { errorMessage, setError } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryKey: ["invoices"],
    queryFn: () => getInvoices(tenantId!),
  });

  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (errorMessage && !data)
    return <ErrorMessage errorMessage={errorMessage} />;

  if (data)
    return (
      <Box>
        <Typography variant="h6" fontWeight={700}>
          {t("DashboardPaymentList.paymentList")}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            gap: 3,
            flexDirection: "column",
          }}
        >
          {data.items.map((invoice, index) => (
            <Box
              key={invoice.id}
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {new Date(invoice.createdAt).toLocaleString(lang, {
                  dateStyle: "short",
                })}
              </Typography>
              <Typography variant="h6" fontWeight={500}>
                {t("DashboardPaymentList.invoice")} #{index + 1}
              </Typography>
              {invoice.invoicePdfUrl && (
                <a
                  href={invoice.invoicePdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="text">
                    {t("DashboardPaymentList.seeInvoice")}
                  </Button>
                </a>
              )}
              {index < 2 && (
                <Divider
                  sx={{
                    width: "100%",
                    mt: 1,
                  }}
                />
              )}
            </Box>
          ))}
          {data.items.length > 0 && (
            <LinkWrapper to={cmsRoutes.invoices.base}>
              <Button color="secondary" fullWidth>
                {t("DashboardPaymentList.more")}
              </Button>
            </LinkWrapper>
          )}
        </Box>
      </Box>
    );
};
