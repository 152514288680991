"use client";

import { useContext, useState } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { BillingForm } from "./BillingForm";
import { Close } from "@mui/icons-material";

export const DashboardBilling = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Typography variant="h6" fontWeight={700}>
        {t("DashboardBilling.billingData")}
      </Typography>
      <Button
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => setModalOpen(true)}
      >
        {t("DashboardBilling.edit")}
      </Button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 4,
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 1.5,
            borderWidth: 1,
            borderColor: "rgba(255, 255, 255, 0.12)",
            borderStyle: "solid",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 500,
            pb: 0,
          }}
        >
          {t("DashboardBilling.billingData")}
          <IconButton onClick={() => setModalOpen(false)}>
            <Close
              sx={(theme) => ({
                fill: theme.palette.text.primary,
              })}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BillingForm onSuccess={() => setModalOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
