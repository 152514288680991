import {
  Box,
  Button,
  FormGroup,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import {
  Card,
  Checkbox,
  ErrorMessage,
  LinkWrapper,
  LoadingButton,
  Logo,
  TextInput,
} from "@repo/ui";
import {
  cmsRoutes,
  confirmPasswordValidator,
  passwordValidator,
  requiredCheckboxValidator,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  confirmEmailSetPassword,
  EmailSetPasswordConfirmationRequest,
} from "@repo/api-config/services/company";
import { ConfirmEmailChangePasswordRouteParams } from "../../routes/_public/confirm-email-change-password";
import { useSearch } from "@tanstack/react-router";
import { useContext, useState } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

interface ConfirmEmailChangePasswordPageForm {
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  acceptMarketing: boolean;
}

export const ConfirmEmailChangePasswordPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { errorMessage, setError } = useServerErrorFormatter();
  const { code, identifier }: ConfirmEmailChangePasswordRouteParams = useSearch(
    {
      strict: false,
    }
  );

  const [confirmed, setConfirmed] = useState(false);
  const [marketingExpanded, setMarketingExpanded] = useState(false);

  if (!code)
    return (
      <ErrorMessage
        errorMessage={t("ConfirmEmailChangePasswordPage.invalidLink")}
      />
    );

  const { mutate: sendForm, isPending } = useMutation<
    void,
    AxiosError,
    EmailSetPasswordConfirmationRequest
  >({
    mutationFn: confirmEmailSetPassword,
    onSuccess: () => {
      setConfirmed(true);
    },
    onError: setError,
  });

  const form = useForm<ConfirmEmailChangePasswordPageForm, any>({
    defaultValues: {
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      acceptMarketing: false,
    },
    onSubmit: async ({ value }) => {
      sendForm({
        tenantId: identifier || "",
        newPassword: value.password.trim(),
        resetCode: code,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card fullscreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Logo height={50} theme="dark" />
      </Box>
      {confirmed ? (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              {t("confirmEmail.confirmed.header")}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t("confirmEmail.confirmed.description")}
            </Typography>
          </Box>
          <LinkWrapper to={cmsRoutes.login.base}>
            <Button fullWidth size="large">
              {t("confirmEmail.confirmed.login")}
            </Button>
          </LinkWrapper>
        </>
      ) : (
        <>
          <Typography variant="h5">
            {t("ConfirmEmailChangePasswordPage.header")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            <FormGroup sx={{ gap: 2, mt: 1 }}>
              <TextInput
                form={form}
                name="password"
                validators={{
                  onChange: passwordValidator,
                }}
                type="password"
                label={t("ConfirmEmailChangePasswordPage.password")}
                required
              />
              <TextInput
                form={form}
                name="confirmPassword"
                validators={{
                  onChangeListenTo: ["password"],
                  onChange: confirmPasswordValidator,
                }}
                type="password"
                label={t("ConfirmEmailChangePasswordPage.confirmPassword")}
                required
              />{" "}
              <FormGroup sx={{ mt: 2, mr: -2 }}>
                <Checkbox
                  form={form}
                  name="acceptTerms"
                  label={
                    <>
                      {t("commonConsents.acceptTerms1")}{" "}
                      <MuiLink
                        href={`https://loymee.com/tos-company/TermsOfService_company_${lang}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("commonConsents.acceptTerms2")}
                      </MuiLink>{" "}
                      {t("commonConsents.acceptTerms3")}{" "}
                      <MuiLink
                        href={`https://loymee.com/privacy/PrivacyPolicy_${lang}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("commonConsents.acceptTerms4")}
                      </MuiLink>
                    </>
                  }
                  variant="body1"
                  validators={{
                    onChange: requiredCheckboxValidator,
                  }}
                  required
                />{" "}
                <Checkbox
                  form={form}
                  name="acceptMarketing"
                  label={
                    <>
                      {t("commonConsents.marketing1")}
                      {!marketingExpanded && "... "}
                      {marketingExpanded &&
                        ` ${t("commonConsents.marketing2")} `}
                      <MuiLink
                        component="button"
                        type="button"
                        sx={{
                          mt: -0.5,
                          fontWeight: 700,
                        }}
                        onClick={() => setMarketingExpanded(!marketingExpanded)}
                      >
                        {t(
                          marketingExpanded
                            ? "commonConsents.less"
                            : "commonConsents.more"
                        ).toUpperCase()}
                      </MuiLink>
                    </>
                  }
                  variant="body1"
                  validators={{
                    onChange: requiredCheckboxValidator,
                  }}
                  required
                  checkboxAtTop
                />
              </FormGroup>
              <LoadingButton
                isLoading={isPending}
                text={t("ConfirmEmailChangePasswordPage.send")}
                size="large"
              />
              <ErrorMessage errorMessage={errorMessage} />
            </FormGroup>
          </form>
        </>
      )}
    </Card>
  );
};
