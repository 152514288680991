import { createFileRoute } from "@tanstack/react-router";
import { ConfirmEmailChangePasswordPage } from "../../../pages/ConfirmEmailChangePassword";

export interface ConfirmEmailChangePasswordRouteParams {
  code?: string;
  identifier?: string;
}

export const Route = createFileRoute("/_public/confirm-email-change-password/")(
  {
    component: ConfirmEmailChangePasswordPage,
    validateSearch: (
      search: Record<string, string>
    ): ConfirmEmailChangePasswordRouteParams => {
      return {
        code: search.code,
        identifier: search.identifier,
      };
    },
  }
);
