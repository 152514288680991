"use client";

import { ThemeOptions, alpha } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteColor {
    contrast?: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    successDark: true;
  }
}

export const lightColorScheme = {
  text: {
    primary: "#181A1A",
    secondary: "#181A1A99",
    disabled: "#181A1A61",
  },
  primary: {
    main: "#CBFD13",
    dark: "#B6E311",
    light: "#D1FD2E",
    contrast: "#181A1A",
  },
  secondary: {
    main: "#E6EE9C",
    dark: "#D4E157",
    light: "#CDDC39",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF5350",
  },
  warning: {
    main: "#EF6C00",
    dark: "#E65100",
    light: "#FF9800",
  },
  info: {
    main: "#0288D1",
    dark: "#01579B",
    light: "#03A9F4",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
    light: "#4CAF50",
  },
  background: {
    default: "#F3F3F7",
  },
  action: {
    active: "#0000008F",
    hover: "#0000000A",
    selected: "#00000014",
    disabledBackground: "#0000001F",
    focus: "#0000001F",
    disabled: "#00000061",
  },
};

export const darkColorScheme = {
  text: {
    primary: "#FFFFFF",
    secondary: "#FFFFFFB2",
    disabled: "#FFFFFF61",
  },
  primary: {
    main: "#CBFD13",
    dark: "#B6E311",
    light: "#D1FD2E",
    contrast: "#181A1A",
  },
  secondary: {
    main: "#E6EE9C",
    dark: "#D4E157",
    light: "#CDDC39",
  },
  error: {
    main: "#F44336",
    dark: "#D32F2F",
    light: "#E57373",
  },
  warning: {
    main: "#FFA726",
    dark: "#F57C00",
    light: "#FFB74D",
  },
  info: {
    main: "#29B6F6",
    dark: "#0288D1",
    light: "#4FC3F7",
  },
  success: {
    main: "#66BB6A",
    dark: "#388E3C",
    light: "#81C784",
  },
  background: {
    default: "#121212",
  },
  action: {
    active: "#FFFFFF8F",
    hover: "#FFFFFF14",
    selected: "#FFFFFF29",
    disabledBackground: "#FFFFFF1F",
    focus: "#FFFFFF1F",
    disabled: "#FFFFFF61",
  },
};

export const themeSettings: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  zIndex: {
    drawer: 12000,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "&:hover": {
            backgroundColor: "rgba(203, 253, 19, 0.8)",
          },
          "&:active": {
            backgroundColor: "rgba(203, 253, 19, 0.4)",
          },
          "&.MuiSvgIcon-root": {
            marginRight: 16,
          },
          "& .MuiButton-startIcon": {
            marginLeft: 0,
          },
          "&.MuiButton-text": {
            color: theme.palette.text.primary,
            textDecoration: "underline",
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.primary.contrast || theme.palette.primary.main,
                0.2
              ),
            },
            "&:focus": {
              backgroundColor: alpha(
                theme.palette.primary.contrast || theme.palette.primary.main,
                0.2
              ),
            },
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          marginTop: 4,
          marginBottom: 4,
          fontWeight: 500,
          lineHeight: "24px",
          "&:hover": {
            backgroundColor: "rgba(230, 238, 156, 0.08)",
          },
          "&:focus": {
            backgroundColor: "rgba(230, 238, 156, 0.12)",
          },
          "&:active": {
            backgroundColor: "rgba(230, 238, 156, 0.16)",
          },
          "& .MuiSvgIcon-root": {
            marginRight: 16,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          variants: [
            {
              props: {
                variant: "outlined",
              },
              style: {
                backgroundColor: theme.palette.grey[900],
              },
            },
          ],
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiDrawer-paperAnchorBottom": {
            backgroundColor: theme.palette.background.default,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(9),
            maxWidth: theme.breakpoints.values.sm,
            margin: "auto",
            ...theme.applyStyles("dark", {
              backgroundImage: "none",
            }),
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          flexShrink: 0,
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.main,
            }),
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              backgroundColor: theme.palette.primary.main,
            }),
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-checked": {
            color: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.main,
            }),
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.contrast,
          textDecoration: "underline",
          textDecorationColor: alpha(
            theme.palette.primary.contrast || theme.palette.primary.main,
            0.4
          ),
          ...theme.applyStyles("dark", {
            color: theme.palette.common.white,
            textDecorationColor: alpha(theme.palette.common.white, 0.4),
          }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.error.main,
          visibility: "hidden",
          "&::before": {
            content: '"*"',
            visibility: "visible",
          },
        }),
        root: ({ theme }) => ({
          "&.Mui-focused": {
            color: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.main,
            }),
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.error.main,
          visibility: "hidden",
          "&::before": {
            content: '"*"',
            visibility: "visible",
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              borderColor: theme.palette.primary.main,
            }),
          },
          "& .MuiInputBase-input": {
            "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-webkit-autofill-strong-password, &:-webkit-autofill-strong-password-viewable, &:-webkit-autofill-and-obscured":
              {
                WebkitBackgroundClip: "text",
                transition: "background-color 5000s ease-in-out 0s",
                fontFamily: "inherit",
                WebkitTextFillColor: "#000000",
                boxShadow: undefined,
                ...theme.applyStyles("dark", {
                  WebkitTextFillColor: "#ffffff",
                  boxShadow: "inset 0 0 20px 20px #23232329",
                }),
              },
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-checked": {
            color: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.main,
            }),
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: "8px 16px",
          fontWeight: 600,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiAlert-colorSuccessDark": {
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.contrast,
          ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.common.white,
          }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-selected": {
            color: theme.palette.primary.contrast,
            ...theme.applyStyles("dark", {
              color: theme.palette.common.white,
            }),
          },
        }),
      },
    },
  },
  typography: {
    fontFamily: "Space Grotesk, serif",
    h1: { fontSize: 96 },
    h2: { fontSize: 60 },
    h3: { fontSize: 40 },
    h4: { fontSize: 34 },
    h5: { fontSize: 24 },
    h6: { fontSize: 20 },
    subtitle1: { fontSize: 16 },
    subtitle2: { fontSize: 14 },
    body1: { fontSize: 16 },
    body2: { fontSize: 14 },
    caption: { fontSize: 12 },
    overline: { fontSize: 12, textTransform: "uppercase" },
  },
};
