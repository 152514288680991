import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  Link as MuiLink,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Button,
  Card,
  Select,
  TextInput,
  ErrorMessage,
  Logo,
  Checkbox,
  LinkWrapper,
  LoadingButton,
} from "@repo/ui";
import {
  RegisterCompanyCommand,
  ResendConfirmationEmailRequest,
} from "@repo/types/companyApi.types";
import {
  registerCompany,
  RegisterCompanyCommandWithCaptcha,
  resendConfirmationEmail,
} from "@repo/api-config/services/company";
import {
  cmsRoutes,
  CompanyCategoryExtended,
  companyCategoryMap,
  companyCategoryValidator,
  confirmPasswordValidator,
  emailValidator,
  passwordValidator,
  phoneValidator,
  requiredCheckboxValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import {
  RegisterCompanyProvider,
  useRegisterCompanyContext,
} from "./RegisterCompanyContext";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useState } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "@tanstack/react-router";
import Turnstile, { useTurnstile } from "react-turnstile";

export const RegisterCompany = () => {
  return (
    <RegisterCompanyProvider>
      <RegisterCompanyForm />
    </RegisterCompanyProvider>
  );
};

const RegisterCompanyForm = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { step } = useRegisterCompanyContext();

  return (
    <Card fullscreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Logo height={50} theme="dark" />
      </Box>
      {step !== "FINAL" && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: -1,
              gap: 1,
            }}
          >
            <IconButton onClick={() => window.history.back()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5">{t("RegisterCompany.header")}</Typography>
          </Box>
          <Typography variant="body2">
            {t("RegisterCompany.step")} {step === "BASIC" ? "1" : "2"}{" "}
            {t("RegisterCompany.of")} 2
          </Typography>
        </>
      )}
      {step === "BASIC" ? <RegisterCompanyFormStepBasic /> : null}
      {step === "ADDRESS" ? <RegisterCompanyFormStepAddress /> : null}
      {step === "FINAL" ? <RegisterCompanyFormStepFinal /> : null}
      {step !== "FINAL" && (
        <Box display="flex" gap={1} justifyContent="space-between">
          <MuiLink component={Link} to={cmsRoutes.login.base}>
            {t("RegisterCompany.backToLogin")}
          </MuiLink>
        </Box>
      )}
    </Card>
  );
};

const RegisterCompanyFormStepBasic = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { t: commonT } = useTranslation(lang, "common");

  const { nextStep, updateFormData, formData } = useRegisterCompanyContext();

  const form = useForm<
    Pick<RegisterCompanyCommand, "companyName" | "category">,
    any
  >({
    defaultValues: formData
      ? {
          companyName: formData.companyName,
          category: formData.category,
        }
      : {
          companyName: "",
          category: CompanyCategoryExtended.Other,
        },
    onSubmit: ({ value }) => {
      updateFormData(value);
      nextStep();
    },
    validatorAdapter: valibotValidator(),
  });

  const sortedCategories = companyCategoryMap
    .map((companyCategory) => ({
      value: companyCategory,
      label: commonT(`companyCategories.${companyCategory}`),
    }))
    .sort((a, b) =>
      a.value === "Other"
        ? 1
        : b.value === "Other"
          ? -1
          : a.label.localeCompare(b.label, lang)
    );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormGroup sx={{ gap: 2, mt: 1 }}>
        <TextInput
          form={form}
          name="companyName"
          validators={{
            onChange: requiredStringValidator,
          }}
          label={t("RegisterCompany.form.companyName")}
          required
        />
        <FormControl required>
          <InputLabel id="company-category">
            {t("RegisterCompany.form.category")}
          </InputLabel>
          <Select
            form={form}
            name="category"
            labelId="company-category"
            id="company-category"
            autoWidth
            label={t("RegisterCompany.form.category")}
            validators={{
              onChange: companyCategoryValidator,
            }}
          >
            {sortedCategories.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <form.Subscribe
          selector={(state) => [state.fieldMeta]}
          children={([fieldMeta]) => {
            if (!fieldMeta) return null;
            const hasErrors = (fields: any) => {
              for (const key in fields) {
                if (fields[key].errors && fields[key].errors.length > 0) {
                  return true;
                }
              }
              return false;
            };

            const isDisabled = hasErrors(fieldMeta);

            return (
              <Button
                type="submit"
                variant="contained"
                disabled={isDisabled}
                size="large"
              >
                {t("RegisterCompany.form.goNext")}
              </Button>
            );
          }}
        />
      </FormGroup>
    </form>
  );
};

interface AddressFormProps extends RegisterCompanyCommand {
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  acceptMarketing: boolean;
  turnstileResponse: string;
}

const RegisterCompanyFormStepAddress = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { errorMessage, setError } = useServerErrorFormatter();
  const { formData, previousStep, updateFormData, nextStep } =
    useRegisterCompanyContext();
  const turnstile = useTurnstile();

  const { mutate: registerCompanyMutation } = useMutation<
    void,
    AxiosError,
    RegisterCompanyCommandWithCaptcha
  >({
    mutationFn: registerCompany,
    onError: (error) => {
      setError(error);
      turnstile.reset();
    },
    onSuccess: (_data, values) => {
      updateFormData(values);
      nextStep();
    },
  });

  const form = useForm<AddressFormProps, any>({
    defaultValues: {
      companyName: formData?.companyName || "",
      category: formData?.category || CompanyCategoryExtended.None,
      ownerEmailAddress: formData?.ownerEmailAddress || "",
      ownerPassword: "",
      phoneNumber: formData?.phoneNumber || "",
      confirmPassword: "",
      password: "",
      acceptTerms: formData?.acceptTerms || false,
      acceptMarketing: formData?.acceptMarketing || false,
      turnstileResponse: "",
    },
    onSubmit: ({
      value: {
        confirmPassword,
        password,
        ownerEmailAddress,
        turnstileResponse,
        ...restValues
      },
    }) => {
      registerCompanyMutation({
        ...restValues,
        ownerEmailAddress: ownerEmailAddress.trim(),
        ownerPassword: password.trim(),
        idempotencyKey: crypto.randomUUID(),
        turnstileResponse,
      });
    },
    validatorAdapter: valibotValidator(),
  });
  const [marketingExpanded, setMarketingExpanded] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          updateFormData(form.state.values);
          previousStep();
        }}
        sx={{
          alignSelf: "start",
          ml: -1,
        }}
        startIcon={<ArrowBack />}
        variant="text"
        size="small"
      >
        {t("RegisterCompany.backToStart")}
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <FormGroup sx={{ gap: 2, mt: 1 }}>
          <TextInput
            form={form}
            name="phoneNumber"
            validators={{ onChange: phoneValidator }}
            handleFieldChange={(value, field) =>
              field.handleChange(value.replace(/\s/g, ""))
            }
            type="tel"
            label={t("RegisterCompany.form.phoneNumber")}
          />
          <TextInput
            form={form}
            name="ownerEmailAddress"
            validators={{ onChange: emailValidator }}
            type="email"
            label={t("RegisterCompany.form.ownerEmailAddress")}
            required
          />
          <TextInput
            form={form}
            name="password"
            validators={{ onChange: passwordValidator }}
            type="password"
            label={t("RegisterCompany.form.password")}
            required
          />
          <TextInput
            form={form}
            name="confirmPassword"
            validators={{
              onChangeListenTo: ["password"],
              onChange: confirmPasswordValidator,
            }}
            type="password"
            label={t("RegisterCompany.form.confirmPassword")}
            required
          />
        </FormGroup>
        <FormGroup sx={{ mt: 2, mr: -2 }}>
          <Checkbox
            form={form}
            name="acceptTerms"
            label={
              <>
                {t("commonConsents.acceptTerms1")}{" "}
                <MuiLink
                  href={`https://loymee.com/tos-company/TermsOfService_company_${lang}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("commonConsents.acceptTerms2")}
                </MuiLink>{" "}
                {t("commonConsents.acceptTerms3")}{" "}
                <MuiLink
                  href={`https://loymee.com/privacy/PrivacyPolicy_${lang}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("commonConsents.acceptTerms4")}
                </MuiLink>
              </>
            }
            variant="body1"
            validators={{
              onChange: requiredCheckboxValidator,
            }}
            required
          />{" "}
          <Checkbox
            form={form}
            name="acceptMarketing"
            label={
              <>
                {t("commonConsents.marketing1")}
                {!marketingExpanded && "... "}
                {marketingExpanded && ` ${t("commonConsents.marketing2")} `}
                <MuiLink
                  component="button"
                  type="button"
                  sx={{
                    mt: -0.5,
                    fontWeight: 700,
                  }}
                  onClick={() => setMarketingExpanded(!marketingExpanded)}
                >
                  {t(
                    marketingExpanded
                      ? "commonConsents.less"
                      : "commonConsents.more"
                  ).toUpperCase()}
                </MuiLink>
              </>
            }
            variant="body1"
            validators={{
              onChange: requiredCheckboxValidator,
            }}
            required
            checkboxAtTop
          />
        </FormGroup>
        <Turnstile
          sitekey={import.meta.env.VITE_TURNSTILE_SITEKEY!}
          refreshExpired="auto"
          size="invisible"
          onVerify={(token) => form.setFieldValue("turnstileResponse", token)}
        />
        <FormGroup sx={{ gap: 2, mt: 2 }}>
          <Button type="submit" variant="contained" size="large">
            {t("RegisterCompany.form.registerCompany")}
          </Button>
          <ErrorMessage errorMessage={errorMessage} />
        </FormGroup>
      </form>
    </>
  );
};

const RegisterCompanyFormStepFinal = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { formData } = useRegisterCompanyContext();

  const { showNotification } = useNotificationsContext();
  const { mutate: resendMail, isPending: resendMailPending } = useMutation<
    void,
    AxiosError,
    ResendConfirmationEmailRequest
  >({
    mutationFn: (value) =>
      resendConfirmationEmail({
        email: value.email.trim(),
      }),
    onSuccess: () =>
      showNotification(t("RegisterCompany.checkEmail.emailSent")),
    onError: () =>
      showNotification(t("RegisterCompany.checkEmail.emailSendError"), {
        type: "error",
      }),
  });

  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t("RegisterCompany.checkEmail.header")}
        </Typography>
        <Typography
          sx={(theme) => ({ color: theme.palette.text.secondary, mb: 4 })}
        >
          {t("RegisterCompany.checkEmail.description")}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 1,
        }}
      >
        <LinkWrapper to={cmsRoutes.login.base}>
          <Button fullWidth>{t("RegisterCompany.checkEmail.goBack")}</Button>
        </LinkWrapper>
      </Box>
      <Box>
        <Typography textAlign="center" sx={{ mb: 1 }}>
          {t("RegisterCompany.checkEmail.didNotReceive")}
        </Typography>
        <LoadingButton
          isLoading={resendMailPending}
          text={t("RegisterCompany.checkEmail.sendAgain")}
          onClick={() => resendMail({ email: formData?.ownerEmailAddress! })}
          variant="text"
        />
      </Box>
    </>
  );
};
