import { useCompanyAuth } from "@repo/api-config";
import { getUserInfo } from "@repo/api-config/services/company";
import { AuthResponse } from "@repo/api-config/types";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { LoginRequest } from "@repo/types/companyApi.types";
import {
  cmsRoutes,
  eraseCookie,
  setCookie,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext } from "react";

export const useLoginForm = () => {
  const auth = useCompanyAuth();
  const navigate = useNavigate();
  const { errorMessage, setError } = useServerErrorFormatter();
  const { lang } = useContext(I18nContext);
  const { t: commonT } = useTranslation(lang, "common");
  const { showNotification } = useNotificationsContext();

  const { mutate: loginMutation, isPending } = useMutation<
    AuthResponse,
    AxiosError,
    LoginRequest
  >({
    mutationFn: (values) => auth.signIn(values),
    onSuccess: async ({ tenantId }) => {
      showNotification(commonT("loggedIn"));
      eraseCookie("loymeeUserRole");
      const data = await getUserInfo(tenantId!);
      setCookie(
        "loymeeUserRole",
        data.userRole || "Owner",
        14 * 24 * 60 * 60 * 1000
      );
      if (data.userRole === "Operator") {
        navigate({ to: cmsRoutes.scanner.base });
      } else if (data.userRole?.includes("Owner")) {
        navigate({ to: "/" });
      } else {
        auth.logout();
      }
    },
    onError: setError,
  });

  const form = useForm<LoginRequest, any>({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ value }) => {
      loginMutation({
        email: value.email.trim(),
        password: value.password.trim(),
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return {
    form,
    isPending,
    errorMessage,
  };
};
