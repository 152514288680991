import { DashboardCompanyLayout } from "./DashboardCompanyLayout";
import { DashboardPendingOwnerLayout } from "./DashboardPendingOwnerLayout";
import { getCookie } from "@repo/utils";

const DashboardPage = () => {
  const role = getCookie("loymeeUserRole");
  if (role === "Owner") return <DashboardCompanyLayout />;
  if (role === "PendingOwner") return <DashboardPendingOwnerLayout />;
};

export default DashboardPage;
