import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import {
  ScanCustomerPublicIdCommand,
  ScanCustomerPublicIdResponse,
  ScanRewardRequestCodeCommand,
  ScanRewardRequestCodeResponse,
} from "@repo/types/companyApi.types";

export const scanCustomerCode = async (
  tenantId: string,
  { customerPublicId }: ScanCustomerPublicIdCommand
) => {
  const response = await httpClient.post<ScanCustomerPublicIdResponse>(
    companyApiPaths.scans.scanCustomerCode(tenantId),
    {
      customerPublicId,
    }
  );

  return response.data;
};

export const scanRewardRequestCode = async (
  tenantId: string,
  { rewardRequestCode }: ScanRewardRequestCodeCommand
) => {
  const response = await httpClient.post<ScanRewardRequestCodeResponse>(
    companyApiPaths.scans.scanRewardRequestCode(tenantId),
    {
      rewardRequestCode,
    }
  );

  return response.data;
};
