"use client";

import {
  Box,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { EmployeeQrScanner } from "./scanner";
import { GreyButton, Loader } from "@repo/ui";
import { useMutation } from "@tanstack/react-query";
import { useCmsRole, useCompanyAuth, useMe } from "@repo/api-config";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useContext, useState } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { ArrowBack, TextFields } from "@mui/icons-material";
import { isSafari } from "react-device-detect";
import { Link, useRouter } from "@tanstack/react-router";

export default function ScannerPage() {
  const { logout } = useCompanyAuth();
  const { navigate } = useRouter();
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { t: commonT } = useTranslation(lang, "common");
  const { showNotification } = useNotificationsContext();
  const [loggingOut, setLoggingOut] = useState(false);
  const { mutate: logoutMutation } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      setLoggingOut(true);
      showNotification(commonT("loggedOut"));
      navigate({ to: cmsRoutes.login.base });
    },
  });
  const smallScreen = useMediaQuery("@media screen and (max-height: 600px)");
  const { userRole } = useMe(useCmsRole());

  const isStandalone = useMediaQuery(
    "@media all and (display-mode: standalone)"
  );

  if (loggingOut) return <Loader />;
  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Box
        sx={{
          px: 3,
          py: 6,
          position: "relative",
          minHeight:
            isSafari && isStandalone
              ? "100svh"
              : "calc(100svh - env(safe-area-inset-bottom, 0))",
          backgroundColor: "#040104",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            {userRole === "Owner" && (
              <Link to={cmsRoutes.home.base}>
                <IconButton
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                >
                  <ArrowBack />
                </IconButton>
              </Link>
            )}
            <Typography
              variant="h5"
              fontWeight={700}
              sx={(theme) => ({ color: theme.palette.common.white })}
            >
              {t("employeeScan.page.header")}
            </Typography>
          </Box>
          <GreyButton onClick={() => logoutMutation()}>
            {t("employeeScan.page.logout")}
          </GreyButton>
        </Box>
        <Box
          sx={
            smallScreen
              ? {}
              : {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  px: 3,
                  transform: "translate(-50%, -50%)",
                }
          }
        >
          <EmployeeQrScanner smallScreen={smallScreen} />
        </Box>
        <Link
          to={cmsRoutes.scanner.text}
          style={{
            alignSelf: "end",
          }}
        >
          <GreyButton
            startIcon={<TextFields />}
            sx={{
              alignSelf: "end",
            }}
          >
            {t("employeeScan.page.text")}
          </GreyButton>
        </Link>
      </Box>
    </Container>
  );
}
