import { Box, useTheme } from "@mui/material";

const Brick = ({ isActive }: { isActive: boolean }) => {
  const theme = useTheme();
  return (
    (<Box
      sx={[{
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.primary.main
      }, isActive ? {
        opacity: 1
      } : {
        opacity: 0.1
      }]}
    />)
  );
};

export const PackageLevel = ({ level }: { level: number }) => {
  return (
    <Box
      sx={{
        width: "22px",
        height: "22px",
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr",
        gap: "2px",
      }}
    >
      <Brick isActive={level > 0} />
      <Brick isActive={level > 1} />
      <Brick isActive={level > 2} />
      <Brick isActive={level > 3} />
    </Box>
  );
};
