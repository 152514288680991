import {
  AccessTokenResponse,
  LoginRequest,
} from "@repo/types/customerApi.types";
import { rootApiPaths } from "./paths";
import { baseApiUrl, httpClient } from "../../http-client";
import {
  ForgotPasswordRequest,
  ResendConfirmationEmailRequest,
  ResetPasswordRequest,
  UserInfoResponse,
} from "@repo/types/rootApi.types";
import axios from "axios";

interface RootRefreshTokenRequest {
  refreshToken: string;
}

export interface EmailConfirmationRequest {
  userId: string;
  code: string;
  changedEmail?: string;
}

export const signIn = async ({ email, password }: LoginRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + rootApiPaths.auth.login.slice(1),
    {
      email,
      password,
    }
  );
  return response.data;
};

export const refreshToken = async ({
  refreshToken,
}: RootRefreshTokenRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + rootApiPaths.auth.refresh,
    {
      refreshToken,
    }
  );
  return response.data;
};

export const getUserInfo = async () => {
  const response = await httpClient.get<UserInfoResponse>(
    rootApiPaths.auth.info
  );

  return response.data;
};

export const confirmEmail = async ({
  code,
  userId,
  changedEmail,
}: EmailConfirmationRequest) => {
  const response = await httpClient.post(
    rootApiPaths.auth.confirmEmail,
    undefined,
    {
      params: { userId, code, changedEmail },
    }
  );
  return response.data;
};

export const resendConfirmationEmail = async ({
  email,
}: ResendConfirmationEmailRequest) => {
  const response = await httpClient.post(
    rootApiPaths.auth.resendConfirmationEmail,
    {
      email,
    }
  );

  return response.data;
};

export const forgotPassword = async ({ email }: ForgotPasswordRequest) => {
  const response = await httpClient.post(rootApiPaths.auth.forgotPassword, {
    email,
  });

  return response.data;
};

export const resetPassword = async ({
  email,
  newPassword,
  resetCode,
}: ResetPasswordRequest) => {
  const response = await httpClient.post(rootApiPaths.auth.resetPassword, {
    email,
    newPassword,
    resetCode,
  });

  return response.data;
};
